<template>

  <b-card>
    <div
      v-if="loader"
      class=" text-center my-2"
    >
      <b-spinner
        type="grow"
        label="Loading..."
      />
    </div>
    <div v-else>
      <validation-observer
        ref="infoRules"
        v-slot="{ invalid }"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="engineerStatusForm.name"
                trim
                placeholder="Name"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="engineerStatusForm.email"
                type="email"
                trim
                placeholder="Email"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">

            <validation-provider
              #default="validationContext"
              name="Main Certificate"
              rules="required"
            >
              <VSelect
                v-model="selectedGroupId"
                :options="certificatesGroups"
                placeholder="Select Main Certificate You want To Edit this Statuses."
                label="text"
                :searchable="true"
                :clearable="false"
                class="d-inline-block w-100"
                :state="getValidationState(validationContext)"
                @input="onGroupChange"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>

          <b-col
            v-if="certificates.length"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Main Certificate"
              rules="required"
            >
              <VSelect
                v-model="selectedCertificateId"
                :options="certificates"
                placeholder="Select Sub Certificate You want To Edit this Statuses."
                label="text"
                :searchable="true"
                :clearable="false"
                class="d-inline-block w-100"
                :state="getValidationState(validationContext)"
                @input="onCertificateChange"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>

        </b-row>

        <b-col
          v-if="showSelectedStatuses"
          md="6"
          class="mt-2"
        >

          <b-form-group
            label="Status "
            label-for="Status"
          >
            <b-form-checkbox-group
              id="status_id"
              v-model="selectedStatuses"
              class="d-flex flex-column"
              :options="statuses"
              trim
              placeholder="Status"
              multiple
            />
          </b-form-group>
        </b-col>

        <b-row>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-if="!loaderBtn && !loaderStatus"
              variant="primary"
              class="mr-1"
              :disabled="invalid || Object.values(errors).length > 0"
              @click="editCertificateStatus"
            >
              Save All Changes
            </b-button>
            <b-button
              v-if="loader || loaderStatus"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner
                small
                type="grow"
              />
              Loading...
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
  </b-card>

</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import axios from 'axios'
import VSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  components: { VSelect },
  setup() {
    const { getValidationState } = formValidation()
    const engineerStatusForm = ref({
      name: '',
      email: '',
      status_id: [],
    })
    return {
      getValidationState,
      engineerStatusForm,
    }
  },
  data() {
    return {
      required,
      certificatesGroups: [],
      certificates: [],
      certificate: [],
      statuses: [],
      selectedGroupId: '',
      selectedCertificateId: '',
      selectedStatuses: [],
      showSelectedStatuses: false,
      loader: false,
      loaderBtn: false,
      loaderStatus: false,
      validationErrors: {},
      errors: {},
    }
  },
  mounted() {
    this.getCertificatesGroups()
    this.getStatusesGroups()
    this.showEngineer()
  },
  methods: {
    onGroupChange() {
      this.showSelectedStatuses = false
      const group = this.certificatesGroups.find(g => g.value === this.selectedGroupId.value)
      this.certificates = group ? group.certificates.map(item => ({
        value: item.id,
        text: `${item.name.ar} - ${item.name.en}`,
        statuses: item.statuses,
      })) : []
      this.selectedCertificateId = ''
    },
    onCertificateChange() {
      this.certificate = this.certificates.find(c => c.value === this.selectedCertificateId.value)

      if (this.certificate) {
        this.showSelectedStatuses = true

        const defaultStatus = this.certificate.statuses.map(s => s.status_id)
        this.selectedStatuses = defaultStatus
      }
    },
    async  showEngineer() {
      if (this.$route.params.id) {
        this.loader = true
        try {
          const response = await axios.get(`/register/site/user/${this.$route.params.id}`)

          if (response.status === 200) {
            const userData = decryptData(response.data.payload).data.user
            this.engineerStatusForm.name = userData.name
            this.engineerStatusForm.email = userData.email
            this.engineerStatusForm.status_id = userData.status.map(item => item.id)
          }
        } catch (error) {
          this.loader = false
          if (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'can\'t get user information',
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }
      }
    },
    async getCertificatesGroups() {
      this.loader = true
      try {
        const response = await axios.get(`engineer/${this.$route.params.id}`)
        if (response.status === 200) {
          this.certificatesGroups = decryptData(response.data.payload).data.engineer.certificates_groups.map(item => ({
            value: item.id,
            text: `${item.name.ar} - ${item.name.en}`,
            certificates: item.certificates,
          }))
          this.loader = false
        }
      } catch (error) {
        this.loader = false
        if (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'can\'t get main certificates',
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        }
      }
    },

    async getStatusesGroups() {
      this.loader = true
      try {
        const response = await axios.get('/certificate-status')
        if (response.status === 200) {
          this.loader = false
          this.statuses = decryptData(response.data.payload).data.status.map(item => ({
            value: item.id,
            text: item.status,
          }))
        }
      } catch (error) {
        this.loader = false
        if (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'can\'t get Sub certificates',
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        }
      }
    },
    async editCertificateStatus() {
      try {
        this.loaderBtn = true
        this.loaderStatus = true
        const payload = {
          data: this.selectedStatuses.map(status => ({
            user_id: this.$route.params.id,
            certificate_id: this.selectedCertificateId.value,
            status_id: status,
          })),
        }
        await axios
          .post('engineer-certificate-status/assign', payload)
          .then(res => {
            if (res.status === 200) {
              this.$router.push({ name: 'engineers' })
              this.$toasted.show('Updated Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
      } catch (error) {
        if (error.response && error.response.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error.response.data.message}`,
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        }
      } finally {
        this.loader = false
        this.loaderStatus = false
      }
    },

  },
}
</script>
